// --------------------------------------------------
//
// Fonts
//
// --------------------------------------------------

// Font Stacks

$font-url-google		:	'https://fonts.googleapis.com/css?family=Oswald|Pacifico|Raleway:400,500';


$font-family-body		:		'Raleway', sans-serif;
$headings-font-family  	:		'Oswald', sans-serif;
$fancy-font			  	:		'Pacifico', cursive;
 
//==== Fonts

$base__font-size		:		16px;
$base__line-height		:		36px;

$h1-line-height		:		40px;
$h2-line-height		:		34px;


p {
	line-height: 	$base__line-height;
	font-size: 		$base__font-size;
}

p a {
	color: $primary-dark;
  	text-decoration: underline;
	
	&:visited {
		text-decoration: underline;
	}
	&:hover {
		color: $secondary;
	}
}

.small {

	line-height: 	$base__line-height*0.7;
}

.quote {
	font-size: 	$base__font-size*1.3;
	line-height: $base__line-height*1.3;
}

.intro  {
	font-size: 	$base__font-size*1.3;	
}

.navbar {
	font-family:  	$headings-font-family;
}

h1, h2, h3 {
	color: 			$secondary;
	font-family:  	$headings-font-family;
}

h1 {
	font-size: 		$base__font-size*2;
	line-height: 	$h1-line-height;
}

h2 {

	font-size: 		$base__font-size*1.6;
	line-height: 	$h2-line-height;
}

h3 {
	font-size: 		1.6em;
	color:			$primary;
	}

@include media-breakpoint-up(lg) {

		h1, h2, h3 {
			margin-bottom:	20px;
		}

		h1 {
			font-size: 		$base__font-size*2.4;
		}

		h2 {

			font-size: 		$base__font-size*2.2;
		}

	}


h4 {
	color:			$primary-dark;
	font-size: 		$base__font-size*1.0;
	border-top: 	1px solid $secondary;
	border-bottom: 	1px solid $secondary;
	padding-top:  	10px;
	padding-bottom: 10px;
}




.fancytitle {
	font-family: $fancy-font;
	font-size: 1.8em;
}

/*  Between lg up */

@media (max-width: 1610px) and (min-width: 800px) { 
	
		.fancytitle {
			font-size: 1.0em;
		}

}


.footer p a {
	color: $primary-dark;
	
	&:hover {
		color: $primary;	}
}