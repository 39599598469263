// Rows

@mixin rowsizing {
	padding: 20px;
}

@mixin scaletransistion {
	-moz-transition: scale .5s ease-in-out; 
    -webkit-transition: scale .5s ease-in-out; 
    transition: scale .5s ease-in-out; 
}