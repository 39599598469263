
$pistachio: 		#95d600;
$english-walnut:	#493829;


$primary:       $pistachio;
$secondary:		$english-walnut;

$primary-light		: 		lighten($primary, 5%);
$primary-dark		: 		darken($primary, 15%);
$secondary-light		: 	lighten($secondary, 25%);
$secondary-dark		: 		darken($secondary, 25%);
$white-dark		: 			darken(white, 15%);


$body-bg:       black;



//== Social Colors

$facebook			:		#3b5998;
$facebook-dark		: 		darken($facebook, 25%);
$twitter			:		#1DA1F2;
$twitter-dark		: 		darken($twitter, 25%);
$google				:		#DB4437;
$google-dark		: 		darken($google, 25%);
$instagram			:		#262626;
$instagram-dark		: 		darken($instagram, 25%);
$linkedin			:		#0077B5;
$linkedin-dark		: 		darken($linkedin, 25%);
$pinterest			:		#BD081C;
$pinterest-dark		: 		darken($pinterest, 25%);
$youtube			:		#282828;
$youtube-dark		: 		darken($pinterest, 25%);

$social-btn-padding-y:         .2rem;
$social-btn-padding-x:         .5rem;
$social-btn-line-height:       .9rem;
