

/* Menu fixed to bottom of SMALL screen */

.btn-flat {
	border-radius: 0px;
	font-family:  $headings-font-family;
	padding: 0.2rem 1rem;
	border-right: 3px white solid;

	&:last-child {
		border-right: none;
	}
}

.fixed-bottom {
	background-color: $primary;
	border-top: 4px white solid;

}

/* ================================================================= */

body {
	font-family: $font-family-body;
	background-color: $secondary;
	overflow-x: hidden; // To stop horizontal scroll on container-fluid
}


.container-fluid {
    padding: 0px;
    margin: auto;
}

#content {
	background: white;
}

//Navigation

//Create sticky top navigation for large screens

.navbar {
	background-color: $secondary;
}

.navbar-toggler {
	color: white;
	margin-bottom: 20px;
	font-size: 2.4em;
}

.nav-link, .dropdown-item {
	font-size: 2em;
}

.PhotoNavBar {
	padding: 10px;
}

//NavBar Image Overlay

.ImageContainer {
	position: relative;
	width: 100%;
}

.NavImage {
	display: block;
	width: 100%;
	height: auto;
}

.overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
	transition: .5s ease;
	text-align: center;
	padding: 20px;
	font-size: 1.4em;
	background: rgba(0, 0, 0, 0.7);
	color: white;
}

.please-review-us {
	margin-bottom: 0px;
}
.footer-stars {
	margin-bottom: 40px;
}
.fa-star {
	color: $primary;
}

@include media-breakpoint-up(lg) {

	body {
		padding-top: 160px;
	}

	.navbar-static-top {
	    position:fixed;
	    top:0;
	    right:0;
	    left:0;
	    z-index: 1000;
	    }

	}

@media (min-width:1500px) {
		
		#content {
		padding-top: 20px;
		}	

}

@media (min-width:1550px) {
		
		#content {
		padding-top: 40px;
		}	

}

@media (min-width:1600px) {
		
		#content {
		padding-top: 60px;
		}	

}

@media (min-width:1700px) {
		
		#content {
		padding-top: 80px;
		}	

}

@include media-breakpoint-up(xl) {

		body { 
		padding-top: 190px;
		}

		/* Scrolled nav with nested element */

		.scrolled-nav {

			height: 210px !important;
				@include scaletransistion;

			 .site-logo  {
				transform: scale(0.6);
				@include scaletransistion;
				}

			.top-text-nav {
				margin-top: 60px;
				font-size: 1.0em;
				}

			.ImageContainer {
				position: relative;
				height: 70%;
				}

			.NavImage {
				height: 100%;
				overflow-y: hidden; 
				}

			.overlay {
				padding: 10px;
				font-size: 1.2em;
				}

			}

		.overlay {
		font-size: 1.8em;
		}

}





//Home Page Image Hover Overlay

.ImageContainer:hover .overlay {
	bottom: 100%;
	height:	0;
	padding: 0px;
}



.MainRow, .PageFooter, .maintitle {
	@include rowsizing;
}


.expandedrow, .expandedrowgray {
	@include rowsizing;
}

.expandedrowgray, .gray-sidebar {
	background: $gray-200;
}


@media (min-width:992px) {
		.expandedrow, .expandedrowgray {
			@include rowsizing;
			padding-bottom: 60px;
			padding-top: 60px;
		}

		.expandedrowgray {
			@include rowsizing;
		}
}



.icongray, .socialmedia a {
	color: $gray-400;
}

.icondark {
	color: $secondary;
}

.specials-card {
	background: $gray-200;
    height: 100%;
}




@media (max-width: 1610px) and (min-width: 1200px) { 
	
		.homepage-specials p {
			font-size: 0.8em;
			line-height: 1em;
		}

}


.tablebooking-card {
	background: $gray-200;
}

/* @include media-breakpoint-up(xl) { 

			.homepage-specials {
				min-height: 303px;
			}


	 } */

/* Meet the Chef - foodchef ============================================================================ */

.foodchef {
	position: relative;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100%;
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 1200px) and (max-width: 1285px) {

	.btn-primary {	font-size: 0.69em;}

	 }

// Small devices (landscape phones, 576px and up)
@media (min-width: 1286px) and (max-width: 1400px) {

	.btn-primary {	font-size: 0.85em;}

	 }


//Main buttons

.btn-custom {	
	background: $primary;
 	color: $secondary;
	font-family: $headings-font-family;
	font-size: 1.4em;
	padding-top: 3px;

	&:hover {	
	 	color: $white-dark;
	 	background: $primary-dark;
	}
}


.btn-homeicons {	
	@extend .btn;
	width: 100% ;
	border-radius: 10px;
	font-size: 1.3em;
	background: $primary;
 	color: $secondary;
	font-family: $headings-font-family;
	padding-top: 6px;

	&:hover {	
	 	color: $white-dark;
	 	background: $primary-dark;
	}
}

.btn-restauranticons {	
	@extend .btn;
	width: 100% ;
	border-radius: 10px;
	font-size: 1.2em;
	background: $primary;
 	color: $secondary;
	font-family: $headings-font-family;
	padding-top: 6px;

	&:hover {	
	 	color: $white-dark;
	 	background: $primary-dark;
	}
}



.btn-main {	
	@extend .btn;
	width: 100% ;
}





//FontAwesome Icon boxes on homepage ==============================================

.fahomeicon {
	font-size: 1.6em;
	}

.farestauranticon {
	font-size: 1.6em;
	}


@include media-breakpoint-up(md) { 

	.fahomeicon {
		margin-top: 10px;
		font-size: 200%;
	}

	.foodchef img {
    min-height: 800px;
	}

	.farestauranticon {
		margin-top: 10px;
		font-size: 200%;
	}

}


@include media-breakpoint-up(lg) { 

	.buttoniconsrow {
	padding-left: 20px;
	}

	.btn-homeicons {	
		font-size: 220%;
		width: 100% !important;
		height: 170px;
	}

	.faicon {
		margin-top: 20px;
	}

	.tours, .vouchers {
		margin-bottom: 10px;
	}

}

@include media-breakpoint-up(xl) {

	.nav-link {
		font-size: 1.4em;
		margin-left: 10px;
	}

	.dropdown-menu {
		margin-left: 20px;
	}

	.dropdown-item {
		font-size: 1.4em;
		margin-bottom: 10px;
	}

	.btn-homeicons {	
		font-size: 120%;
		width: 100% !important;
 		height: 94px !important;
	}

	.faicon {
		margin-top: 5px;
		font-size: 2.2em;
	}

}


/*  Between lg up */

@media (max-width: 1610px) and (min-width: 1200px) { 
	
	.btn-homeicons {	
		font-size: 80% !important;
		width: 100% !important;
		max-height: 68px !important;
	}

	.faicon {
		font-size: 180% !important;
	}

}


/*  Between above and XL */

@media (max-width: 1810px) and (min-width: 1610px) { 
	
	.btn-homeicons {	
		font-size: 110% !important;
		width: 100% !important;
		max-height: 88px !important;
	}

	.faicon {
		font-size: 180% !important;
		margin-top: 10px;
	}

}




//Content boxes on homepage with grey backgrounds

.homebox {
	background: $gray-200;
	height: 100%;
	padding: 0;
	margin: 0px;
}

.homepagetext {
	margin: 20px;
}



/* Above 800 px home box is 460 pixels high like photos */
@media (min-width:811px) {

	.homebox {
		height: 460px;
	}
}



/* Parallax images ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.parallax, .parallax-restaurant, .parallax-pub-garden, .parallax-roast, .parallax-outdoor-dining { 

    /* Set a specific height */
    height: 500px; 

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.parallax { 

	/* Set a specific height */
    height: 400px; 

    /* The image used */
    background-image: url("https://thewychwoodinn.com/assets/img_parallax-800.jpg");

}

/* Only override this if one of the conditions for the 1500w image is met */
@media 
  (min-width: 960px) and (max-width: 2000px) {
  .parallax {
	/* Set a specific height */
    height: 500px;
    background-image: url("https://thewychwoodinn.com/assets/img_parallax.jpg");
  }
}


.parallax-restaurant { 
    /* The image used */
    background-image: url("https://thewychwoodinn.com/assets/restaurant-parallax.jpg");
}

.parallax-pub-garden { 
    /* The image used */
    background-image: url("https://thewychwoodinn.com/assets/pub-garden-parallax.jpg");
}

.parallax-roast { 
    /* The image used */
    background-image: url("https://thewychwoodinn.com/assets/sunday-roast-parallax.jpg");
}

.parallax-outdoor-dining { 
    /* The image used */
    background-image: url("https://thewychwoodinn.com/assets/outdoor-eating-parallax.jpg");
    height: 800px; 
}


/* Home page +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

.fancytitle {
	font-family: $fancy-font;
	font-size: 1.8em;
}

/*  Between lg up */

@media (max-width: 1610px) and (min-width: 1200px) { 
	
		.fancytitle {
			font-size: 1.0em;
		}

}

.accommodationcotswolds {
	background-image: url("https://thewychwoodinn.com/assets/wychwood-accommodation-cotswolds.jpg");
}

.foodcotswolds {
	background-image: url("https://thewychwoodinn.com/assets/wychwood-food-cotswolds.jpg");
}

.pubcotswolds {
	background-image: url("https://thewychwoodinn.com/assets/wychwood-pub-cotswolds.jpg");
}

.accommodationcotswolds, .foodcotswolds, .pubcotswolds {
	background-size: cover;
	height: 460px;
}



//HomePage Bottom Images Overlay


.bottomimagecontainer {
	position: relative;
	width: 100%;
}

.bottomimage {
	display: block;
	width: 100%;
	height: auto;
}

.bottomoverlay {
	display:block;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.6);
	overflow: hidden;
	width: 100%;
	height: 100%;
	transition: .5s ease;
	text-align: center;
	padding: 20px;
	font-size: 1.4em;
}

.bottomimagecontainer:hover .bottomoverlay {
	overflow: hidden;
	bottom: 100%;
	height: 0;
	padding: 0px; 
}




@media (min-width:768px) and (max-width:901px) {

	.bottomoverlay {
		font-size: 90%;
	}

}



// The Rooms +++++++++++++++++++++++++++++++++++++++++++++++

.btn-room {
	width: 100%;

	background: $primary;
 	color: $secondary;
	font-family: $headings-font-family;
	font-size: 0.8em;
	padding-top: 3px;

	&:hover {	
	 	color: $white-dark;
	 	background: $primary-dark;
	}

}

@include media-breakpoint-up(sm) { 

		.btn-room {
			font-size: 1.4em;
			 }

	 }

@include media-breakpoint-up(xl) { 

		.btn-room {
			font-size: 1.1em;
			 }

	 } 

.room-btn-icon {
	font-size: 160%;
	margin-top: 6px;
	margin-bottom: 6px;
	color: $white;
} 

.maintitle {
	background-color: $gray-200;
	margin-top: 20px;
}

.ineachroom  {

}

.figure {
	margin: 0;
	}

/* Masonry fluid 3 columns */
.grid-sizer,
.grid-item { 
	width: 33%;
	padding-left: 2%;
	padding-bottom: 2%;
	 }



/* STAY page ++++++++++++++++++++++++++++++++++++++++++++ */
.card.stay-card {
	margin-bottom: 30px;
}

.stay-card-text {
	font-size: 0.8em;
	line-height: 1.5em;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

.stay-card-body {
    padding-bottom: 0em;

}

/* Second row of cards center */

.card.stay-card {
        margin: 0 auto; 
        float: none; 
        margin-bottom: 30px; 
}


/* Make grid responsive */

$grid-gutter-width: 30px;

// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 5
);

@each $name, $breakpoint in $grid-breakpoints {
	@media (min-width: $breakpoint) {
		.card-deck .card {
			flex: 0 0 calc(#{100/map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
		}
	}
}



// Eat +++++++++++++++++++++++++++++++++++++++++++++++

ul.menulist  { 

	list-style-type: none;

	li {
	  list-style-type: none;
	}

	a {
		color: $secondary;
		text-decoration: underline;
	}

}



// MailChimp +++++++++++++++++++++++++++++++++++++++++++++++

#mc_embed_signup {background:#fff; clear:left; font:14px $font-family-body; }

/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */

#mc-embedded-subscribe-form input[type=checkbox]{display: inline; width: auto;margin-right: 10px;}

#mergeRow-gdpr {
	font-size: 		$base__font-size*0.85;
	line-height: 	$base__line-height*0.8;
	margin: 0px !important;
	padding-top: 30px;
	}

#mergeRow-gdpr fieldset label {font-weight: normal;}

#mc-embedded-subscribe-form .mc_fieldset{border:none;min-height: 0px;padding-bottom:0px;}

#mc_embed_signup div.response {

    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: 3% !important;
    font-weight: normal;
    top: -1.5em;
    z-index: 1;
    width: 100% !important;

}

.placeofinterest {
	background-color: $gray-200;
	padding: 10px;
	margin-bottom: 20px;
}
.placeofinterest small, .placeofinterest p {
	margin: 0px;
}

.placeofinterest h5 {
	color: $gray-600;
}


#table-booking {
	width:710px;
	height:485px
}

/* 7" tablet */
@media only screen and (max-width: 709px)
	{
	#table-booking {width:610px;height:585px}
	}
/* phone landscape */
@media only screen and (max-width: 609px)
	{
	#table-booking {width:480px;height:585px}
	}
/* phone portrait*/
@media only screen and (max-width: 479px)
	{
	#table-booking {width:320px;height:855px}
	}




/* Internet Explorer ======================================================= */

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	
     /* IE10+ CSS styles go here */


		body { 
		padding-top: 0px;
		}

		#content {
		padding-top: 0px;
		}

		.navbar {
	    position:relative;
	    }	

	    .site-logo  {
	    	width: 50%;
	    	margin-top: 20px;
		}

	    .PhotoNavBar {
	    	display: none;
	    }





}